import React, { useEffect, useState } from 'react';
import alumniColumnSchema from './static/alumniColumns.json';
import candidateColumnSchema from './static/candidateColumns.json';
import Step1 from './Step1/Step1';
import { Button, Spinner } from 'react-bootstrap';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import { FiMinimize2, FiMaximize2 } from 'react-icons/fi';
import './bulkuploader.css';
import Loader from './Loader';
import { MdError } from 'react-icons/md';
import Finish from './Finish/Finish';
import { matchColumns, getAddCandidateConfig } from '../../../../api/company';
import { ADMIN_URL } from '../core-components/Utilities/CommonConstants';
import AppConstants from '../../../../core-components/AppConstants';
import SelectPackage from './SelectPackage/SelectPackage';
const isSA = ADMIN_URL.some((str) => AppConstants.baseURL.toString().includes(str));

const BulkUploader = ({ flow = 'alumni', companyId, ...props }) => {
  // flow is 'alumni' or 'candidate'
  const schema = {
    alumni: alumniColumnSchema,
    candidate: candidateColumnSchema
  };
  const [currentStep, setCurrentStep] = useState(0);
  const [useFullScreen, setUseFullScreen] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(schema[flow]);
  const [msg, setMsg] = useState({
    load: false,
    msg: '',
    disableSubmits: false
  });
  const [finalData, setFinalData] = useState();
  const [matchedColumns, setMatchedColumns] = useState([]);
  const [usedOptions, setUsedOptions] = useState([]);
  const [fileId, setFileId] = useState(null);
  const steps = ['Upload Data', 'Match Columns', 'Review & Fix'];
  flow === 'alumni' ? steps.push('Finish') : steps.push('Select Package');

  const [config, setConfig] = useState([]);
  useEffect(() => {
    if (flow === 'candidate') {
      const saCompanyId = isSA
        ? new URLSearchParams(document.location.search).get('company_id')
        : '';
      getAddCandidateConfig(saCompanyId).then((res) => {
        setConfig(res?.data);
      });
    }
  }, []);

  useEffect(() => {
    if (currentStep === 0) {
      setMsg({
        load: false,
        msg: ''
      });
      setHeaders([]);
      setRows([]);
      setColumns(schema[flow]);
      setMatchedColumns([]);
      setFinalData();
      setUsedOptions([]);
    } else if (currentStep === 3) {
      setUseFullScreen(false);
    }
  }, [currentStep]);

  useEffect(() => {
    if (headers.length > 0 && rows.length > 0) {
      setMsg({
        load: false,
        msg: 'Analysing file and matching columns...',
        disableSubmits: true
      });
      const requiredSheetsColumns = Object.keys(columns).map((key) => ({
        key,
        name: columns[key].name
      }));
      let userSheetColumns = headers
        .slice(1)
        .map((option, i) => ({ label: option.label, value: rows[0][i] }));
      // if sheet is same as example sheet dont call match API
      if (preMatchedColumns(userSheetColumns)) {
        const matchedArray = requiredSheetsColumns.map((obj) => ({
          ...obj,
          matched_column: obj.name === 'Full Name' ? 'Employee Full Name' : obj.name,
          name: undefined
        }));
        setMatchedColumns(matchedArray);
        setMsg({
          load: false,
          msg: '',
          disableSubmits: false
        });
      } else
        matchColumns(requiredSheetsColumns, userSheetColumns)
          .then((res) => {
            setMatchedColumns(JSON.parse(res?.data?.aiResponse));
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setMsg({
              load: false,
              msg: '',
              disableSubmits: false
            });
          });
    }
  }, [rows, headers]);

  const ownHeaders =
    flow === 'alumni'
      ? [
          'Staff ID *',
          'Employee Full Name *',
          'Date of Birth *\r\n(YYYY-MM-DD)',
          'Designation *',
          'Date of Joining *\r\n(YYYY-MM-DD)',
          'Last Working Day *\r\n(YYYY-MM-DD)',
          'Phone Number',
          'Personal Email',
          'Business Email',
          'Employment \r\nType',
          'Exit Type',
          'Exit Reason',
          'Eligible for Rehire',
          "Reporting Manager's \r\nName",
          "Reporting Manager's \r\nDesignation",
          "Reporting Manager's \r\nEmail",
          "Reporting Manager's \r\nPhone",
          'CTC'
        ]
      : flow === 'candidate'
      ? [('Name *', 'Surname', 'Phone Number', 'Employee ID', 'Email *')]
      : [];
  const preMatchedColumns = (userSheetColumns) => {
    if (userSheetColumns.length === 0) return false;
    const headers = userSheetColumns.map((col) => col.label);
    const arraysAreEqual = (arr1, arr2) =>
      arr1.length === arr2.length &&
      arr1.every(
        (value, index) =>
          value
            ?.toLowerCase()
            ?.replaceAll('(yyyy-mm-dd)', '')
            ?.replaceAll(/[\n\r* ]/g, '') ===
          arr2[index]
            ?.toLowerCase()
            ?.replaceAll('(yyyy-mm-dd)', '')
            ?.replaceAll(/[\n\r* ]/g, '')
      );
    return arraysAreEqual(headers, ownHeaders);
  };

  const isRequiredColumnsMatched = () => {
    const requiredColumns = Object.keys(columns).filter((key) => {
      if (flow === 'alumni' && ['staff_id', 'date_of_birth'].includes(key)) {
        return !columns['staff_id']?.selectedHeader && !columns['date_of_birth']?.selectedHeader;
      }
      return columns[key].required && !columns[key]?.selectedHeader;
    });
    return requiredColumns.length > 0;
  };

  return (
    <div
      className='blk-container'
      style={
        useFullScreen
          ? {
              width: '100vw',
              height: '100%',
              position: 'fixed',
              top: isSA ? '50px' : 0,
              left: 0,
              backgroundColor: 'white',
              overflow: 'auto',
              paddingBottom: isSA ? '50px' : 0
            }
          : {}
      }
    >
      <div
        className='blk-header'
        onClick={() => {
          console.log(columns);
        }}
      >
        <div className='left' style={{ display: 'flex' }}>
          Add in Bulk
        </div>
        <div className='steps'>
          {steps.map((step, index) => (
            <>
              <div
                key={index}
                className='step'
                style={{
                  color:
                    index < currentStep ? '#8d8d8d' : index === currentStep ? 'black' : '#8d8d8d'
                }}
              >
                {step}
              </div>
              {index < steps.length - 1 && (
                <div
                  className='spacer'
                  style={{
                    color: index < currentStep ? '#8d8d8d' : '#8d8d8d'
                  }}
                >
                  {'>'}
                </div>
              )}
            </>
          ))}
          {useFullScreen ? (
            <FiMinimize2
              style={{
                fontSize: '1.5rem',
                color: '#2a2a2a',
                cursor: 'pointer',
                marginLeft: '12px'
              }}
              onClick={() => {
                setUseFullScreen(!useFullScreen);
              }}
            />
          ) : (
            <FiMaximize2
              style={{
                fontSize: '1.5rem',
                color: '#2a2a2a',
                cursor: 'pointer',
                marginLeft: '12px'
              }}
              onClick={() => {
                setUseFullScreen(!useFullScreen);
              }}
            />
          )}
        </div>
      </div>
      <div className='blk-step-container' style={useFullScreen ? { marginBottom: '90px' } : {}}>
        {msg.load && <Loader />}
        {currentStep === 0 && (
          <Step1
            setRows={setRows}
            setHeaders={setHeaders}
            sample={ownHeaders}
            flow={flow}
            setFileId={setFileId}
          />
        )}
        {currentStep === 1 && (
          <Step2
            options={headers}
            columns={columns}
            setColumns={setColumns}
            rows={rows}
            matchedColumns={matchedColumns}
            setMsg={setMsg}
            flow={flow}
            usedOptions={usedOptions}
            setUsedOptions={setUsedOptions}
          />
        )}
        {currentStep === 2 && (
          <Step3
            columns={columns}
            setColumns={setColumns}
            setCurrentStep={setCurrentStep}
            setFinalData={setFinalData}
            flow={flow}
            config={config}
            history={props.history}
            companyId={companyId}
            finalData={finalData}
          />
        )}
        {((flow === 'alumni' && currentStep === 3) ||
          (flow === 'candidate' && currentStep === 4)) && (
          <Finish setCurrentStep={setCurrentStep} data={finalData} />
        )}
        {flow === 'candidate' && currentStep === 3 && (
          <SelectPackage
            {...props}
            finalData={finalData}
            isSA={isSA}
            setMsg={setMsg}
            fileId={fileId}
          />
        )}
      </div>
      {currentStep < 2 && (
        <div className={`blk-foot`}>
          <div>
            {msg.msg && (
              <div className='msg'>
                <Spinner animation='border' size='sm' style={{ margin: '0px 8px 3px 0px' }} />
                {msg.msg}
              </div>
            )}
            {!msg.msg && currentStep === 1 && isRequiredColumnsMatched() && (
              <div className='msg'>
                <MdError style={{ marginRight: '10px' }} />
                Please match all required columns
              </div>
            )}
          </div>
          <div className='btnContainer'>
            {currentStep > 0 && (
              <Button
                onClick={() => {
                  if (currentStep > 0) {
                    if (currentStep === 1 || currentStep === 2) {
                      setHeaders([]);
                      setRows([]);
                    }
                    if (currentStep === 1) {
                      window.location.reload();
                    } else setCurrentStep(currentStep - 1);
                  }
                }}
                variant='outline-primary'
                className='cancelButton'
              >
                {currentStep === 2 ? 'Re-Upload' : 'Back'}
              </Button>
            )}
            <Button
              onClick={() => {
                if (currentStep < 2) {
                  setCurrentStep(currentStep + 1);
                }
              }}
              disabled={
                !headers.length ||
                !rows.length ||
                (currentStep === 1 && isRequiredColumnsMatched()) ||
                msg.disableSubmits
              }
              variant='primary'
              className='saveButton'
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BulkUploader;
